import { Component } from '@angular/core';
import { TimeHintTagComponent } from '../time-hint-tag/time-hint-tag.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TransportOrder } from '../services/transport-order/transport-order.model';

interface HintCellRendererParams extends ICellRendererParams<TransportOrder> {
  dateFieldKey: string;
}

@Component({
  selector: 'app-hint-cell-renderer',
  standalone: true,
  imports: [
    TimeHintTagComponent
  ],
  templateUrl: './hint-cell-renderer.component.html',
  styleUrl: './hint-cell-renderer.component.scss'
})
/**
 * This component is a custom cell renderer for the ag-grid. It displays a hint tag with the time difference between the current date and the date of the appointment.
 * When using this renderer, set the property params.dateFieldKey to the path of the date property (separated by '.') you want to compare with the current date.
 */
export class HintCellRendererComponent implements ICellRendererAngularComp {
  params?: ICellRendererParams<TransportOrder>;
  public date: Date | undefined = undefined;
  public currentDate = () => new Date();

  agInit(params: HintCellRendererParams): void {
    this.params = params;
    this.date = this.getPropByStringPath<Date>(params.data, params.dateFieldKey);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  private getPropByStringPath<T>(objectToCheck: TransportOrder | undefined, propStringPath: string): T | undefined {
    let objectToReturn: unknown = objectToCheck;
    if (!propStringPath || !objectToReturn) {
      return undefined;
    }
    try {
      const propStringArray = propStringPath.split('.');
      for (const propString of propStringArray) {        
        const candidate = (objectToReturn as TransportOrder)[propString as keyof TransportOrder];
        objectToReturn = candidate;
      }
      
    } catch {
      return undefined;
    }

    return objectToReturn as T;
  }
}
