import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DateService {
    public getTimeDelta(date1: Date, date2: Date): number {
        const date1Ms = date1.getTime();
        const date2Ms = date2.getTime();
        const delta = date1Ms - date2Ms;

        return delta;
    }

    public getTimeDeltaInHours(date1: Date, date2: Date): number {
        return this.getTimeDelta(date1, date2) / 1000 / 60 / 60;
    }

    public getTimeDeltaInMinutes(date1: Date, date2: Date): number {
        return this.getTimeDelta(date1, date2) / 1000 / 60;
    }

    public getTimeInMSToDayHoursAndMinutes(ms: number) {
        let seconds = ms / 1000;
        const days = Math.trunc(seconds / 86400);
        seconds = seconds % 86400;
        const hours = Math.trunc(seconds / 3600);
        seconds = seconds % 3600;
        const minutes = Math.trunc(seconds / 60);
        seconds = seconds % 60;
    
        return `${(days && days > 0) ? days + 'T' : ''} ${hours} Stunden ${minutes}min`;
    }

    public areDatesOnSameDay(date1: Date, date2: Date): boolean {
        const predicateResult = date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
        return predicateResult;
    }
}
