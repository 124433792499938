import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { DsHeaderModule, DsHeaderTagConfiguration, DsNavigationBarModule, DsNavigationItem, DsToastService } from '@bmw-ds/components';
import { environment } from '../environments/environment';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    DsHeaderModule,
    DsNavigationBarModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Transportaufträge';

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: environment.environmentName };
  userInfo = {
    username: 'John Doe',
    department: 'Dept 1',
    profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
  };

  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
    {
      label: 'Transportaufträge',
      routerLink: '/transportorder',
      icon: 'truck',
    },
    {
      label: 'Transportmonitor',
      routerLink: '/transportmonitor',
      icon: 'computer',
    },
    {
      label: 'Dienstleister',
      routerLink: '/service-provider',
      icon: 'service',
    },
  ];

  constructor(private oidcSecurityService: OidcSecurityService,
    private toastService: DsToastService,
  ) { }

  async ngOnInit(): Promise<void> {
    if (!environment.authEnabled) {
      return;
    }

    try {
      const loginResult: LoginResponse = await firstValueFrom(this.oidcSecurityService.checkAuth());
      if (!loginResult.isAuthenticated) {
        this.oidcSecurityService.authorize();
        return;
      }
    } catch {
      this.toastService.pushToast({
        toastText: 'Anmeldung fehlgeschlagen',
        tone: 'critical',
      });
    }
  }
}
