<div class="row mb-4x">
    <div class="col-12 ds-toast-no-show-close">
        <h1>Transportmonitor</h1>
    </div>
</div>
<app-custom-toast-notification *ngIf="showCreateTransportOrderToast()" [showToast]="showCreateTransportOrderToast()" (timeElapsedEvent)="createTransportOrder()"
    (abortCreationEvent)="abortTransportOrderCreation()"></app-custom-toast-notification>
<ds-box class="mt-4x" ds-loading-area-container>
    <ds-box-content>
        <div class="row">
            <div class="col-sm-8">
                <div class="row vehicle">
                    @for(vehicle of vehicles(); track vehicle.id)
                    {
                    @if (!hasTransportOrder(vehicle)) {
                    <div [class]="getColClass(vehicle)" (click)="setSelectedVehicle(vehicle)"
                        (keydown)="setSelectedVehicle(vehicle, $event)"
                        (focus)="setSelectedVehicle(vehicle)" role="button" [tabIndex]="$index" aria-disabled="true">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                {{ vehicle.licensePlate}}
                            </div>
                        </div>
                        @if(vehicle.pairing) {
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <ds-icon icon="factory"></ds-icon>{{ vehicle.pairing.guideNumber}}
                            </div>
                        </div>
                        }
                    </div>
                    } @else {
                    <div [class]="getColClass(vehicle)">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                {{ vehicle.licensePlate}}
                            </div>
                        </div>
                        @if(vehicle.pairing) {
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <ds-icon icon="factory"></ds-icon>{{ vehicle.pairing.guideNumber}}
                            </div>
                        </div>
                        }
                    </div>
                    }
                    } @empty {
                    <span class="col-12 no-vehicles-found">
                        Keine Fahrzeuge gefunden
                    </span>
                    }
                </div>
            </div>
            <div class="col-sm-4">
                <div class="row h-100">
                    @for(zone of zones(); track zone.id)
                    {
                    <div [class]="getZoneColClass(zone)" (click)="setSelectedZone(zone)"
                        (keydown)="setSelectedZone(zone, $event)" (focus)="setSelectedZone(zone)" role="button"
                        [tabIndex]="$index" aria-disabled="true" [style]="zoneHeight()">
                        <div class="d-flex h-100 justify-content-center align-items-center">
                            {{ zone.name }}
                        </div>
                    </div>
                    } @empty {
                    <span class="col-12 no-target-zones-found">
                        Keine Quellareale gefunden
                    </span>
                    }
                </div>
            </div>
        </div>
    </ds-box-content>
</ds-box>

<ng-template #cancelCreation>
    <button ds-button variant="ghost" aria-label="Erstellung des Transportauftrags abbrechen"
        (click)="abortTransportOrderCreation()">Abbrechen</button>
</ng-template>