import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { DsImprintModule } from '@bmw-ds/components';
import { environment } from '../environments/environment';
import { ENVIRONMENT } from '@bmw-spp/bmw-spp-frontend-common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor, LogLevel, provideAuth } from 'angular-auth-oidc-client';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor()])),
    importProvidersFrom(
      DsImprintModule.forRoot({
        phone: '5555555',
        electronicContact: 'change-me@bmwgroup.com'
      }),
    ),
    provideAuth({
      config: {
        authority: environment.issuer,
        redirectUrl: environment.redirectURL,
        postLogoutRedirectUri: environment.redirectURL,
        clientId: environment.clientId,
        scope: 'openid profile email address bmwids organization groups',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        secureRoutes: [environment.secureRouteRootUrl],
        historyCleanupOff: true,
        ignoreNonceAfterRefresh: true,
      }
    }),
    { provide: ENVIRONMENT, useValue: environment },
    ...environment.providers,
  ]
};
