import { Injectable } from "@angular/core";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { TransportOrder } from "./transport-order.model";
import { TransportOrderService } from "./transport-order.service";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TransportOrderBackendService extends BaseBackendService<TransportOrder> implements TransportOrderService {
    public async upsertTransportOrder(transportOrder: TransportOrder): Promise<void> {
        const url = `${environment.apiUrl}${this.getCreateEndpoint()}/`;
        const response = await firstValueFrom(this.httpClient.post<TransportOrder>(url, transportOrder));
        this.data.set([...this.data(), response]);
    }

    protected override getEndpoint(): string {
        return "v1/transport-order";
    }

    private getCreateEndpoint(): string {
        return "v1/transport-order/create-transport-order";
    }

    public override async loadAll(): Promise<TransportOrder[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}/get-transport-orders`;
        const vehicles = await firstValueFrom(this.httpClient.get<TransportOrder[]>(url));
        console.log(vehicles);
        const parsedVehicles = vehicles.map(this.mapBackendToFrontend);
        this.data.set(parsedVehicles);

        return parsedVehicles;
    }

    protected override mapBackendToFrontend(item: TransportOrder): TransportOrder {
        return {
            tenantAlias: item?.tenantAlias,
            object: item.object,
            reporter: item?.reporter,
            assignee: item?.assignee,
            status: item?.status,
            sourceLocation: item?.sourceLocation,
            sourceFence: item?.sourceFence,
            destinationLocation: item?.destinationLocation,
            destinationFence: item?.destinationFence,
            startTs: new Date(item.startTs),
            endTs: item.endTs ? new Date(item.endTs) : undefined,
            targetTs: new Date(item.targetTs),
            omloxSyncTs: item?.omloxSyncTs,
            id: item?.id,
            transportTrackable: {
                id: "",
                vehicle: {
                    id: "",
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    licensePlate: (item as any)?.transportTrackable.vehicle?.vehicleLicensePlate,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    carType: (item as any)?.vehicle?.transportTrackable.vehicleType,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    vin: (item as any)?.vehicle?.transportTrackable.vehicleIdentificationNumber,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    visitReason: (item as any)?.transportTrackable.vehicle?.visitReason,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    internalContact: (item as any)?.transportTrackable.vehicle?.internalContact,
                },
            },
        };
    }

    public async getTransportOrdersForServiceProvider(serviceProviderId: string): Promise<TransportOrder[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}/get-supplier-transport-orders?serviceProvider=${serviceProviderId}`;
        const transportOrders = await firstValueFrom(this.httpClient.get<TransportOrder[]>(url));
        console.log(transportOrders);
        const parsedVehicles = transportOrders.map(this.mapBackendToFrontend);
        this.data.set(parsedVehicles);

        return parsedVehicles;
    }
}