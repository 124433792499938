import { signal } from "@angular/core";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { firstValueFrom } from "rxjs";
import { ServiceProvider } from "./service-provider.model";
import { ServiceProviderService } from "./service-provider.service";
import { environment } from "../../../environments/environment";

export class ServiceProviderBackendService extends BaseBackendService<ServiceProvider> implements ServiceProviderService {
    
    override dataChangesAreMonitored = signal(false);
    override data = signal<ServiceProvider[]>([]);

    protected override getEndpoint(): string {
        return "v1/config/service-provider-config";
    }

    override async loadAll(): Promise<ServiceProvider[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}`;
        const serviceProvider = await firstValueFrom(this.httpClient.get<ServiceProvider[]>(url));
        this.data.set(serviceProvider);

        return serviceProvider;
    }
}