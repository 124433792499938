import { Routes } from '@angular/router';
import { TransportOrderComponent } from './transport-order/transport-order.component';
import { AuthGuard, PageNotFoundComponent } from '@bmw-spp/bmw-spp-frontend-common';
import { TransportMonitorComponent } from './transport-monitor/transport-monitor.component';
import { environment } from '../environments/environment';
import { ServiceProviderComponent } from './service-provider/service-provider.component';

export const routes: Routes = [
    { path: 'transportorder', component: TransportOrderComponent, canActivate: environment.authEnabled ? [AuthGuard] : [] },
    { path: 'transportmonitor', component: TransportMonitorComponent, canActivate: environment.authEnabled ? [AuthGuard] : [] },
    { path: 'service-provider', component: ServiceProviderComponent},
    { path: '', redirectTo: '/transportorder', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];
