<ds-box *ngIf="showToast()">
    <ds-box-content surfaceTone="positive">
        <div class="col-12 mb-4x">
            <div class="row">
                <div class="col-xs-12 col-6 d-flex align-items-center">
                    <h2>Transportauftrag wird erstellt...</h2>
                </div>
                <div class="col-xs-12 col-6 d-flex justify-content-end">
                    <button ds-button aria-label="Abbrechen" (click)="abortCreation()" variant="destructive">Abbrechen</button>
                </div>
            </div>
        </div>
        <div class="col-12">
            <ds-progress-bar aria-label="Fortschrittsanzeige der Erstellung eines Transportauftrag"
                variant="determinate" [progress]="progress"></ds-progress-bar>
        </div>
    </ds-box-content>
</ds-box>