import { Component, computed, OnInit, signal } from '@angular/core';
import { DsBoxModule, DsToastService } from '@bmw-ds/components';
import { TransportOrderService } from '../services/transport-order/transport-order.service';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { ColDef } from '@ag-grid-community/core';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ChangeStatusCellRendererComponent } from '../change-status-cell-renderer/change-status-cell-renderer.component';
import { HintCellRendererComponent } from '../hint-cell-renderer/hint-cell-renderer.component';

@Component({
  selector: 'app-service-provider',
  standalone: true,
  imports: [
    AgGridAngular,
    DsBoxModule,
  ],
  templateUrl: './service-provider.component.html',
  styleUrl: './service-provider.component.scss'
})
export class ServiceProviderComponent implements OnInit{
  localeText = AG_GRID_LOCALE_DE;
  
  public transportorder = computed(() => {
    return this.transportOrderService.data();
  });

  columns = signal<ColDef<TransportOrder>[]>([
    {
      field: 'id',
      headerName: 'ID',
      filter: 'agTextColumnFilter',
      sortable: false,
      valueGetter: (params) => {
        if (params.data?.id) {
          return params.data.id;
        } else {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          }); 
        }
      }
    },
    {
      field: 'transportTrackable.vehicle.licensePlate',
      headerName: 'Fahrzeug',
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Verspätung',
      filter: 'agTextColumnFilter',
      cellRenderer: HintCellRendererComponent,
      cellRendererParams: {
        dateFieldKey: 'targetTs',
      },
    },
    {
      headerName: 'Restzeit',
      filter: 'agTextColumnFilter',
      sortable: false,
      valueGetter: (params) => {
        if (params.data?.endTs) {
          return '';
        }

        if (params.data?.targetTs) {
          const targetTs = new Date(params.data.targetTs);
          const now = new Date();
          const diff = targetTs.getTime() - now.getTime();
          const diffInMinutes = Math.floor(diff / 60000);
          return diffInMinutes > 0 ? `${diffInMinutes} min` : '0 min';
        }
        
        return '0 min';
      }
    },
    {
      field: 'sourceLocation.name',
      headerName: 'Von',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'destinationLocation.name',
      headerName: 'Nach',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Standort',
      sortable: false,
    },
    {
      headerName: 'Aktionen',
      cellRenderer: ChangeStatusCellRendererComponent,
    }
  ]);

  constructor(private transportOrderService: TransportOrderService,
    private toastService: DsToastService
  ) {}

  async ngOnInit(): Promise<void> {
      try {
        await this.transportOrderService.getTransportOrdersForServiceProvider('serviceProvider-1');

        this.toastService.pushToast({
          toastText: 'Transportaufträge für Dienstleister geladen',
          tone: 'positive',
        });
      } catch {
        this.toastService.pushToast({
          toastText: 'Fehler beim Laden der Transportaufträge',
          tone: 'critical',
        });
      }
  }
}
