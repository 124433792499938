import { Component, computed, OnInit, signal, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { DsBoxModule, DsButtonModule, DsIconModule, DsLoadingAreaModule, DsLoadingAreaOverlayContainerDirective, DsLoadingAreaService, DsToastModule, DsToastService } from '@bmw-ds/components';
import { VehiclesService } from '../services/vehicles/vehicles.service';
import { CommonModule } from '@angular/common';
import { Vehicle } from '@bmw-spp/bmw-spp-frontend-common';
import { TransportOrderService } from '../services/transport-order/transport-order.service';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { ZonesService } from '../services/zones/zones.service';
import { Zone } from '../services/zones/zone.model';
import { CustomToastNotificationComponent } from "../custom-toast-notification/custom-toast-notification.component";
import { Areal } from '../services/zones/areal.model';

@Component({
  selector: 'app-transport-monitor',
  standalone: true,
  imports: [
    CommonModule,
    DsBoxModule,
    DsButtonModule,
    DsIconModule,
    DsToastModule,
    DsLoadingAreaModule,
    CustomToastNotificationComponent
  ],
  templateUrl: './transport-monitor.component.html',
  styleUrl: './transport-monitor.component.scss'
})
export class TransportMonitorComponent implements OnInit {
  @ViewChild(DsLoadingAreaOverlayContainerDirective)
  loadingAreaContainer!: DsLoadingAreaOverlayContainerDirective;

  public vehicles = computed(() => {
    return this.vehiclesService.data();
  });
  private transportOrder = computed(() => {
    return this.transportOrderService.data();
  });
  public zones = computed(() => {
    return this.zonesService.data();
  });
  public zoneHeight = computed(() => {
    const foundVehiclesCount = this.vehicles().length;
    const numberOfRows = Math.ceil(foundVehiclesCount / 3);
    if (numberOfRows <= 1) {
      return undefined;
    } else {
      return `height: ${(100 / numberOfRows) * 3}%`;
    }
  });
  public selectedVehicle: WritableSignal<Vehicle | undefined> = signal<Vehicle | undefined>(undefined);
  public selectedZone = signal<Areal | undefined>(undefined);

  public showCreateTransportOrderToast = signal<boolean>(false);

  @ViewChild('cancelCreation') cancelCreation: TemplateRef<unknown> | undefined;

  constructor(private vehiclesService: VehiclesService,
    private transportOrderService: TransportOrderService,
    private zonesService: ZonesService,
    private toastService: DsToastService,
    private loadingAreaService: DsLoadingAreaService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.vehiclesService && this.vehiclesService.data && this.vehiclesService.data().length === 0) {
      try {
        const vehiclesPromise = this.vehiclesService.loadAll();
        const transportOrderPromise = this.transportOrderService.loadAll();
        const zonesPromise = this.zonesService.loadAll();
        await Promise.all([vehiclesPromise, transportOrderPromise, zonesPromise]);

        this.toastService.pushToast({
          tone: 'positive',
          toastText: 'Fahrzeuge und Areale erfolgreich geladen.'
        });
      } catch (error) {
        this.toastService.pushToast({
          tone: 'critical',
          toastText: `Fehler beim Laden. Versuchen Sie es später erneut. ${(error as Error).message}`
        });
      }
    }
  }

  public getColClass(vehicle: Vehicle) {
    const baseCssClasses = 'h-100 p-2x';
    const activeCssClass = !this.hasTransportOrder(vehicle) ? 'active-vehicle' : 'disabled-vehicle';
    const foundVehiclesCount = this.vehicles().length;
    const selectedClass = this.selectedVehicle()?.licensePlate == vehicle.licensePlate ? 'selected-object' : '';

    return foundVehiclesCount > 1 ? `col-sm-4 ${baseCssClasses} ${activeCssClass} ${selectedClass}` : `col-sm-12 ${baseCssClasses} ${activeCssClass} ${selectedClass}`;
  }

  public getZoneColClass(zone: Zone) {
    const baseCssClasses = 'zone p-2x';
    const foundZonesCount = this.zones().length;
    const selectedClass = this.selectedZone()?.id == zone.id ? 'selected-object' : '';
    if (foundZonesCount >= 12) {
      return `col-sm-1 ${baseCssClasses} ${selectedClass}`;
    } else {
      return foundZonesCount > 0 ? `col-sm-${Math.ceil(12 / foundZonesCount)} ${baseCssClasses} ${selectedClass}` : `col-sm-12 ${baseCssClasses} ${selectedClass}`;
    }
  }

  public setSelectedVehicle(vehicle: Vehicle | undefined, event?: KeyboardEvent) {
    if (vehicle) {
      if (event && event.key === 'Enter') {
        this.selectedVehicle.set(vehicle);
      } else {
        this.selectedVehicle.set(vehicle);
      }
    }
  }

  public setSelectedZone(zoneId: Zone | undefined, event?: KeyboardEvent) {
    if (this.selectedVehicle && this.selectedVehicle() && zoneId) {
      if (event && event.key === 'Enter') {
        this.selectedZone.set(zoneId);
      } else {
        this.selectedZone.set(zoneId);
      }
      
      // Show tooltip before creation of transport order
      this.showCreateTransportOrderToast.set(true);
      console.log(this.loadingAreaContainer);
      this.loadingAreaService.addLoadingArea({
        id: 'transport-monitor-create-order',
        container: this.loadingAreaContainer,
        backdropVariant: 'translucent',
      });
    }
  }

  public hasTransportOrder(vehicle: Vehicle) {
    return this.transportOrder().some((transportOrder: TransportOrder) => {
      return transportOrder.transportTrackable.vehicle.licensePlate === vehicle.licensePlate;
    });
  }

  public abortTransportOrderCreation() {
    this.selectedVehicle.set(undefined);
    this.selectedZone.set(undefined);
    this.showCreateTransportOrderToast.set(false);
    this.loadingAreaService.removeLoadingArea('transport-monitor-create-order');
  }

  public async createTransportOrder() {
    try {
      await this.transportOrderService.upsertTransportOrder({
        assignee: 'Max Mustermann',
        object: {},
        destinationFence: this.selectedZone()!.zones && this.selectedZone()!.zones!.length > 0 ? this.selectedZone()!.zones![0] : {id: '', name: ''},
        destinationLocation: this.selectedZone()!,
        transportTrackable: {
          vehicle: this.selectedVehicle()!,
          id: '',
        },
        id: '',
        tenantAlias: '',
        reporter: '',
        status: 'UNSPECIFIED',
        sourceLocation: {
          id: "10",
          name: "Test",
          zones: [],
        },
        sourceFence: {
          id: "10",
          name: "Test zone",
        },
        startTs: new Date(),
        targetTs: new Date(),
        omloxSyncTs: ''
      });

      this.selectedVehicle.set(undefined);
      this.selectedZone.set(undefined);
      this.showCreateTransportOrderToast.set(false);
      this.loadingAreaService.removeLoadingArea('transport-monitor-create-order');

      this.toastService.pushToast({
        toastText: 'Transportauftrag wurde erfolgreich erstellt.',
        tone: 'positive'
      });
    } catch {
      this.toastService.pushToast({
        toastText: 'Es ist ein Fehler beim Erstellen des Transportauftrags aufgetreten.',
        tone: 'critical'
      });
    }
  }
}
