import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { DsButtonModule } from '@bmw-ds/components';

@Component({
  selector: 'app-change-status-cell-renderer',
  standalone: true,
  imports: [
    DsButtonModule
  ],
  templateUrl: './change-status-cell-renderer.component.html',
  styleUrl: './change-status-cell-renderer.component.scss'
})
export class ChangeStatusCellRendererComponent {
  params?: ICellRendererParams<TransportOrder>;

  agInit(params: ICellRendererParams<TransportOrder>): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }

  changeStatus(status: string): void {
    console.log(`Changed status to ${status} for ${this.params?.data?.id}`);
  }
}
