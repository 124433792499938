import { signal } from "@angular/core";
import { ZonesService } from "./zones.service";
import { Areal } from "./areal.model";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";

export class ZonesBackendService extends BaseBackendService<Areal> implements ZonesService {
    
    override dataChangesAreMonitored = signal(false);
    override data = signal<Areal[]>([]);

    protected override getEndpoint(): string {
        return "v1/areals/get-areals";
    }

    override async loadAll(): Promise<Areal[]> {
        const url = `${environment.apiUrl}${this.getEndpoint()}`;
        const areals = await firstValueFrom(this.httpClient.get<Areal[]>(url));
        console.log(areals);
        this.data.set(areals);

        return areals;
    }
}