<div class="row mb-4x">
    <div class="col-10">
        <h1>Dienstleister</h1>
    </div>
</div>
<ds-box>
    <ds-box-content>
        <div class="col-12">
            <ag-grid-angular class="ag-theme-density tra-grid"
                [rowData]="transportorder()" [columnDefs]="columns()" domLayout="normal"
                [localeText]="localeText" [rowHeight]="130" [autoSizeStrategy]="{type: 'fitCellContents'}">
            </ag-grid-angular>
        </div>
    </ds-box-content>
</ds-box>