import { CommonModule } from '@angular/common';
import { Component, effect, EventEmitter, input, Output } from '@angular/core';
import { DsBoxModule, DsButtonModule, DsProgressBarModule } from '@bmw-ds/components';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-custom-toast-notification',
  standalone: true,
  imports: [
    CommonModule,
    DsBoxModule,
    DsButtonModule,
    DsProgressBarModule
  ],
  templateUrl: './custom-toast-notification.component.html',
  styleUrl: './custom-toast-notification.component.scss'
})
export class CustomToastNotificationComponent {
  @Output()
  timeElapsedEvent = new EventEmitter<void>();
  @Output()
  abortCreationEvent = new EventEmitter<void>();

  showToast = input.required<boolean>();
  public progress = 100;
  private creationAborted = false;

  constructor() {
    effect(async () => {
      if (this.showToast()) {
        const steps = environment.notificationDurationMS / 1000;
        for (let i = 0; i <= steps; i++) {
          if (this.creationAborted) {
            return;
          }

          const newProgress = 100 - i * (100 / steps);
          this.progress = newProgress;
          await this.delay(1000);
        }

        if (!this.creationAborted) {
          this.timeElapsedEvent.emit();
        }
      }
    });
  }

  public abortCreation() {
    this.creationAborted = true;
    this.progress = 100;
    this.abortCreationEvent.emit();
  }

  private async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
