<div class="row mb-1x">
    <div class="col-12">
        <button ds-button class="w-100" (click)="changeStatus('accept')">Annehmen</button>
    </div>
</div>
<div class="row mb-1x">
    <div class="col-12">
        <button ds-button class="w-100" (click)="changeStatus('on-way')">Als unterwegs markieren</button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <button ds-button class="w-100" (click)="changeStatus('arrived')">Als angekommen markieren</button>
    </div>
</div>